<template>
  <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
    <background-parallax v-bind:backgroundOpacityProp="'0.6'" />
    <div class="mt-12 pt-12" style="position: relative"></div>
    <v-sheet
      class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark"
      elevation="6"
    >
      <h1 class="page-header display-1 font-weight-medium white--text">
        Frequently Asked Questions
      </h1>
    </v-sheet>

    <v-card
      class="smc-box box box-rounded box-transparent box-transparent-light text-left"
      elevation="12"
    >
      <v-card-title>
        <h3 class="secondary--text">
          Will the customer always go for the cheapest price?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <p>
          Service My Car.co.za has done extensive market research on how
          customers select their preferred workshop. In North America the
          research has shown that only 15% of customers select the cheapest
          option. There are many factors that influence the customer’s decision
          on which service centre to select, these factors include:
        </p>
        <ul>
          <li>Distance to the workshop</li>
          <li>Workshop reputation and reviews</li>
          <li>
            Value added services that the workshop offers, such as pick up/ drop
            off or car valet
          </li>
          <li>How long the workshop will take to complete the job</li>
          <li>
            Does the workshop have availability to service the customer’s
            vehicle on the his or her preferred date
          </li>
          <li>
            Does the workshop offer a suitable payment solution for the customer
          </li>
          <li>
            Is the workshop going to use original or generic parts on the
            customers vehicle
          </li>
        </ul>
        <p class="mt-6">
          We want to emphasize that customers do not only make their decision
          based on price, therefor meaning the cheapest quote will not
          necessarily always be selected.
        </p>
      </v-card-text>
      <v-card-title>
        <h3 class="secondary--text">
          How does Service My Car.co.za bring my workshop more customers?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <p>
          At Service My Car.co.za we use the latest digital marketing techniques
          to drive customers towards our platform, which indirectly drives
          potential customers towards our Workshop Partners. There are over 700
          000 annual google searches in South Africa for the phrase “car service
          near me”, we are able to direct these potential customers to our
          Workshop Partners. Many workshops, service centres and dealerships
          have not invested in providing their customers a seamless online
          solution for quotations, bookings, amendments and payments. Our
          Workshop Partners are at a huge competitive advantage by being
          represented on our platform, because we are able to direct potential
          customers to their workshop and allow them the opportunity to quote on
          new business.
        </p>
      </v-card-text>
      <v-card-title>
        <h3 class="secondary--text">
          Does Service My Car.co.za take any customers away from my workshop?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <p>
          Our goal has always been to benefit the customer and the workshop. We
          are never going to show favor to any specific workshop or service
          centre at any time. The choice of which workshop to use is entirely up
          to the customer, we just manage the process and provide a platform
          where workshops can gain new customers. We understand that many
          workshops have their loyal customers, these people are not our target
          market. Customers loyal to a specific workshop will in all likelihood
          contact their trusted workshop directly, not making use of Service My
          Car.co.za. Our target market is the customer who is in search of a
          reliable place to have their vehicle serviced or repaired, which
          allows our Workshop Partners to have access to entirely new customers.
        </p>
        <p>
          Service My Car.co.za also provides each of our Workshop Partners with
          all the customer and vehicle information for jobs done by that
          workshop through our platform. Meaning the workshop can market to
          these customers directly in the future, which will increase their
          loyal customer base.
        </p>
      </v-card-text>
      <v-card-title>
        <h3 class="secondary--text">
          How is sign up to Service My Car.co.za free? What’s the catch?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        There is absolutely no catch. Service My Car.co.za will provide all our
        Workshop Partners with a tablet, software, back-end support and training
        completely free of charge. We will only ever charge our Workshop
        Partners a very small commission on each service or repair that a
        customer has already paid for. Meaning there is no risk to our Workshop
        Partners by being a part of our network. Service My Car.co.za only works
        because it works for our Workshop Partners. Workshops only pay a small
        commission of customers received via Service My Car.co.za.
      </v-card-text>
      <v-card-title>
        <h3 class="secondary--text">
          What happens if a customer pays using the online payment method?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <p>
          Service My Car.co.za provides a customer the option to pay securely
          online after a job is complete. The customer does not necessarily have
          to select this option and can always pay the workshop directly when
          collecting the vehicle. Service My Car.co.za uses a payment provider
          that ensures money reflects at the end of every day, regardless of
          which bank the workshop or the customer banks with.
        </p>
      </v-card-text>
      <v-card-title>
        <h3 class="secondary--text">
          What if a car arrives at the workshop and the vehicle diagnosis was
          incorrect or additional work needs to be done?
        </h3>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <p>
          Service My car.co.za allows the workshop to amend quotations if
          necessary, we will automatically contact the customer on the
          workshop’s behalf for them digitally approve any changes to the
          initial quoted price. The workshop will be notified as soon as the
          customer has approved the amendment so that the additional work can
          commence. We also provide the workshop and customer with an accurate
          record of all changes made and approved in order to limit potential
          disputes over work done and pricing.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import backgroundParallax from "@/components/widgets/background-parallax";

export default {
  name: "faq",
  components: {
    backgroundParallax,
  },
};
</script>

<style scoped>
h3 {
    word-break: break-word;
}
</style>