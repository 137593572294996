<template>
  <div>
    <gmap-map
      ref="gmapRef"
      :zoom="6"
      :center="centerPosition"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
      }"
    >
      <!-- inserting an empty polygon, since the geometry api is not loaded on the page if there is none -->
      <gmap-polygon :editable="false" :visible="false"></gmap-polygon>
      <gmap-marker
        v-for="(place, index) in placesProp"
        v-bind:key="index"
        :animation="4"
        :position="place"
        :clickable="true"
        :draggable="false"
        :icon="{
          url: require('@/assets/images/workshop-marker-v1.png'),
          scaledSize: {
            width: 30,
            height: 48,
          },
        }"
      >
      </gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapHelper } from "@/helpers";

export default {
  name: "workshops-map",
  props: {
    placesProp: Array,
  },
  data: () => ({
    centerPosition: mapHelper.defaultCenterPosition(),
    zoom: mapHelper.defaultZoomLevel(),
    places: [],
  }),
  computed: {
    google: gmapApi,
  },
  watch: {
    async placesProp() {
      this.places = this.placesProp;
    },
  },
  async created() {
    this.places = this.placesProp;
  },
};
</script>

<style scoped>
.vue-map-container {
  height: 700px;
}
</style>