<template>
  <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
    <background-parallax v-bind:backgroundOpacityProp="'0.6'" />
    <div class="mt-12 pt-12" style="position: relative;"></div>
    <v-sheet class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark" elevation="6">
      <h1 class="page-header display-1 font-weight-medium white--text">
        Service My Car
        <br v-if="$vuetify.breakpoint.xsOnly" />
         Policy
         </h1>
    </v-sheet>

    <v-card class="smc-box box box-rounded box-transparent box-transparent-light" elevation="12">
      <v-card-title class="terms-title subtitle-1">
        Service My Car (Pty) Ltd policies are in place to ensure the safe and secure interactions between Customers and Workshops.
        We aim to ensure that all parties adhere to our policies and failing to do so will result in immediate exclusion from our platform.
      </v-card-title>
      <v-card-text class="text-left">
        <v-expansion-panels focusable hover popout style="z-index:0;">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-1-circle</v-icon>Basic Principles
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>All our workshop partners have been classified (Certified or Posh) and gone through a strict selection process by qualified SMC personnel upon registration.</li>
                <li>Our workshop partners have been hand picked to best ensure that the customer receives a service that satisfies his/her expectations.</li>
                <li>Each workshop partner is classified as either “Posh” or “Certified” which differentiates them based on various workshop standards such as equipment, machinery, throughput, qualifications of the mechanics and other distinguishing factors.</li>
                <li>All our Workshop partners have committed to providing the customer a satisfactory service or repair to the best of their ability.</li>
                <li>Our workshop partners must adhere to industry standards when providing services, repairs, upgrades or anything similar to a customer. If a workshop should fail to adhere to these standards, the workshop partner shall be liable for any additional changes or repairs that may be needed to rectify the issue. This will include:
                  <ul class="mt-4">
                    <li>Failing to repair or replace a part that is broken if the workshop has committed to doing so.</li>
                    <li>Negligence when having to identify major repairs or procedures that are required and that a trained mechanic should have reasonably been able to identify. If this repair or procedure was included in the initial or amended quotation.</li>
                    <li>Causing damage to any of the vehicles parts, panels, interior and exterior components by virtue of malpractice.</li>
                    <li>Making any changes to a customers vehicle that makes it in any way unroadworthy according to South African Law.</li>
                    <li>Failing to remove any loose parts or debris from the vehicle that will most likely cause damage to the customers vehicle.</li>
                    <li>Contact admin@servicemycar.co.za or 010 312 6846 for any questions relating to use of the platform.</li>
                  </ul>
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-2-circle</v-icon>Correspondence between workshop partners and customers
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>Workshop partners are required to respond to quotations within the specified time period that is predetermined by SMC (3 hours). If a workshop fails to respond to a customers quote request by the lapse of the time period, that workshop will lose the opportunity to provide a quotation to that customer.</li>
                <li>Majority of the communication between workshop partners and customers will be facilitated using the SMC platform. This will include quotations, amendments and final invoices.</li>
                <li>Workshop partners are committed to responding to messages on the SMC platform within 3 business hours.</li>
                <li>Workshop partners are prohibited from communicating with customers outside of the SMC platform unless it is a matter of utmost importance and the customer contends to doing so. This will include any amendments to quotes or any additional services offered. All additional services or amendments must be made through the SMC platform.</li>
                <li>Any improper conduct from workshop partners or customers including butnot limited to hate speech, racism, profanity or anything of the sort will result in immediate exclusion from the SMC platform.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-3-circle</v-icon>Commitment to final quoted and invoiced prices (Customer and Workshop Partner)
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>A workshop partner must commit to the final quoted price after all amendments have been accepted by the customer. There may be no amendments to a final invoice, if this is identified without notification to SMC and the customer other than due to an extraordinary circumstance, it will result in the workshop partner being potentially excluded from the SMC platform.</li>
                <li>Misrepresentation of any quotation will in no way be tolerated and a workshop must quote as accurately as one would expect a well run workshop in accordance with industry standards to do.</li>
                <li>Any amendments to the quotation by the workshop partner must be logged in the platform and must be accepted by the customer on the SMC platform before proceeding with any additional services or repairs.</li>
                <li>The customer must commit to paying the final accepted invoiced price including all accepted amendments in order for the workshop to release the vehicle.</li>
                <li>The workshop partner must accept any proof of payment supplied by SMC or a SMC approved payment provider as fact and any outstanding payments will be dealt with between SMC and the workshop partner.</li>
                <li>All refunds for work completed is to be managed between customer and workshop partner, SMC hold no responsibility for refunds.</li>
                <li class="font-weight-bold">SMC accepts no responsibility for any erroneous quotations or invoices, regardless of the nature of the error and regardless of which party caused the error.</li>
                <li class="font-weight-bold">The customer must make use of the SMC approved payment provider when paying the workshop upon completion of the job. The customer may NOT pay the workshop directly.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-4-circle</v-icon>Agreement between workshop partner and SMC (Service My Car - South Africa)
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>Workshop partners have agreed by way of a legal contract to commit to any final customer accepted price. The workshop has till the point at which the customer’s vehicle has arrived at the workshop partner’s premises to rectify any errors in initial quot. Once the vehicle has arrived for scheduled booking the workshop partner is obliged to commit to the previously quoted price. Any extraordinary circumstances that involve severe financial or reputation damage (as determined by SMC) will be considered and the renegotiation of price and booking date will be mediated by SMC.</li>
                <li>SMC will provide a well maintained database of all a workshop partners’ jobs completed through our SMC platform. This is to provide a record of what was done to each customers vehicle and will assist dealing with return customers.</li>
                <li>SMC will not provide any personal customer information, as determined by SMC, if the customer has not accepted that specific workshops quotation.</li>
                <li>If a workshop partner has been flagged by SMC for regular quoting errors, overbooking, poor customer service or any other issue that may adversely affect the reputation of SMC and/or the booking process that the SMC platform facilitates, it may result in the exclusion of that workshop partners profile from the SMC platform (entirely at the discretion of SMC).</li>
                <li>SMC reserves the right to exclude any workshop partner or customer from the SMC platform for any reason SMC finds necessary.</li>
                <li>Please feel free to contact SMC if you feel a customer has violated any of the SMC policies.</li>
                <li>Customers are obliged to have paid via the SMC approved payment method prior to leaving the workshop partners premises after the work has been completed. </li>
                <li>All refunds for work completed is to be managed between customer and workshop partner, SMC hold no responsibility for refunds. SMC will not charge commission on these customers, the onus is on the workshop to prove that these customers were refunded in full. </li>
                <li class="font-weight-bold">If the workshop partner loses the Electronic Tablet, that workshop partner is liable for R2000.00. Regardless of how the electronic tablet was lost or stolen.</li>
                <li>If there are any questions regarding the agreement please email admin@servicemycar.co.za</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-5-circle</v-icon>Agreement between SMC (Service My Car - South Africa) and the customer
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>SMC commits to providing the best customer service (within reason) that the platform and SMC staff can.</li>
                <li class="font-weight-bold">SMC will facilitate online payments using an approved payment provider in a manner that is transparent, trustworthy and secure to the best of our ability.</li>
                <li>SMC will keep client data secure from all other third parties not involved in regular SMC business practices (Subject to (E) d.) . Personal information will only be shared with a workshop partner once the customer has accepted that specific workshop partner’s quotation, in order for the booking to be successfully completed. The workshop will have access to all customer and vehicle data once a job is completed and a customer has collected their vehicle in order to keep a record of work done on the vehicle.</li>
                <li class="font-weight-bold">If the customer indicates they are interested in test driving a new vehicle via our platform, SMC will send the Customer’s Name, Surname, Contact details, Current vehicle and vehicle the customer intends to test drive to our nearest Workshop Partner that has the ability to facilitate a test drive for that specific vehicle.</li>
                <li class="font-weight-bold">The Clients physical address will at no time be given to any Workshop Partner (Unless the customer has agreed to do so), only the suburb (and distance) in which the customer requesting the quote has entered will be shown to the Workshop Partner.</li>
                <li>Only Vehicle data will be shared with workshop partners when submitting a quotation request in order for our workshop partners to provide a sufficiently accurate quotation.</li>
                <li class="font-weight-bold">Vehicle data includes: Make, Model, Mileage, Service or Repair requirements, VIN, Engine number and License number.</li>
                <li>SMC will not be liable for any errors from any party, misquotations, damage to vehicles by any party, incorrect booking dates, incorrect procedures performed on any vehicle, or any issue of the sort.</li>
                <li>SMC will attempt help both workshop partners and customers mediate any disputes (provided the dispute is not being resolved in a court of law)</li>
                <li>SMC will provide on online payment solution for the customer to pay for the service, the payment is made using and SMC approved online payment solutions provider and paid directly to the workshop partner and not SMC. Therefore any payment disputes must be resolved between the customer, workshop partner and online payment provider.</li>
                <li>SMC reserves the right to exclude any workshop partner or customer from the SMC platform for any reason SMC may find appropriate to do so.</li>
                <li>SMC will not be liable in any way if the SMC platform (www.servicemycar.co.za) fails in any way or causes damage in any way to a customer or workshop partner.</li>
                <li>Customers are prohibited from communicating with workshop partners outside of the SMC platform unless it is a matter of utmost importance or has no impact on the booking date or final invoice price - this communication will include any amendments to quotes or any additional services offered. All additional services or amendments must be made through the SMC platform.</li>
                <li>SMC will not charge customers for making use of the SMC platform unless the customer is aware and agreed to such charges prior booking.</li>
                <li>Customers must pay using the SMC approved online payment provider.</li>
                <li>SMC will (to the best of our ability) assist the customer through the booking process.</li>
                <li>Please feel free to contact SMC if you feel a workshop partner has violated any of the SMC policies.</li>
                <li>Customers are obliged to have paid via the SMC approved payment method (PayFast) prior to leaving the workshop partners premises with their vehicle after the work has been completed. </li>
                <li>All refunds for work completed is to be managed between customer and workshop partner, SMC hold no responsibility for refunds.</li>
                <li>If there are any questions regarding the agreement please email admin@servicemycar.co.za</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-6-circle</v-icon>Compliance with POPIA
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>SMC remains committed to the Protection of Personal Information Act 4 of 2013 (POPIA) and has taken all necessary measures to ensure we are line and fully compliant with POPIA.</li>
                <li>Any amendments to POPIA are automatically incorporated into SMC’s policy guidelines and SMC retains the right to update any policy, with immediate effect, in order to bring such policy in line with POPIA.</li>
                <li>Workshop partners will therefore also bound to the principles of POPIA and the onus is on the workshop partners to ensure all activities carried out are in line with POPIA. Workshop partners carry the onus to regularly review POPIA in order to keep up to date with its rules and guidelines.</li>
                <li>SMC will not be liable in any way for any transgression of POPIA committed by one of the workshop partners.</li>
                <li>SMC does not process any personal information without the authorised consent of the customer. Furthermore, when consent is withdrawn so too is the processing of the personal information.</li>
                <li>All personal information is obtained directly from the customer and is not received from a third party or by any other means.</li>
                <li>SMC does not, under any circumstances, transfer any personal information to third parties, outside of the contractual agreement, both within and outside the Republic.</li>
                <li>All SMC customer personal information is only retained for the necessary purpose of achieving the aim of the contractual agreement and other lawful purposes. Every customer retains the right to have their personal information deleted from the record which will occur with immediate effect after SMC has been notified by such customer.</li>
                <li>SMC maintains documentation and records of all processing operations under its responsibility.</li>
                <li>SMC has taken all precautionary measures to establish and maintain appropriate safeguards to protect personal information against internal and external risks.</li>
                <li>In the event of a breach of SMC safeguards or any related security compromise, SMC will notify all parties as soon as reasonably possible after the discovery of the compromise. SMC will bear the duty, and take extensive measures, to keep all affected parties informed and updated on the matter as well as all corrective steps taken.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-7-circle</v-icon>Amendments to SMC Policies
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>SMC reserves the right to, at any time, amend the above policies and conditions contained at any time without prior warning to Customers with immediate effect.</li>
                <li>Workshop partners will be bound by the workshop partner agreement, changes made by SMC that will result in material financial implications will be communicated within a reasonable time frame.</li>
                <li>It is the onus of the customer and Workshop partner to review these policies in order to keep up to date.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-8-circle</v-icon>Restrictions of use
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>
                  Neither the Workshop Partner or the Customer may:
                  <ul class="mt-4">
                    <li>Modify, change, alter or disable the website or the application in any way.</li>
                    <li>Distribute any parts of the website or application without prior SMC consent in writing.</li>
                    <li>Sell or in any way restrict access to the website or application, the right to do so is solely owned by SMC.</li>
                    <li>Collect any data (other than that allowed by SMC) in any way.</li>
                  </ul>
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-9-circle</v-icon>Ownership of the website content
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>The website and application is owned and operated by Service My Car (Pty) Ltd. This includes anything on the website and application that falls under the general definition of “content”.</li>
                <li>All contact information on the website and its databases are prohibited to be used for any marketing, advertising or in any way that may result in financial gain by any other party besides SMC.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list-item-title>
                <v-icon class="mr-4">mdi-numeric-10-circle</v-icon>Limitation of Liability
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="body-1">
              <br />
              <ol>
                <li>SMC is in no way liable for any loss, damage, injury or death in any circumstances to any persons, equipment, hardware, software, vehicles or anything of the sort whether the loss or damage was caused directly or indirectly by SMC, its customers or its workshop partners.</li>
                <li>Although all workshop partners have passed SMC’s internal quality assessment, SMC takes no responsibility for any damage or loss caused by any of our workshop partners. SMC only aims to provide a platform on which customers and workshop partners may interact and provide vehicle related goods and services.</li>
                <li>The customer shall indemnify SMC for any material or repetitional loss caused by the customer or the Workshop Partner.</li>
                <li>The workshop partner shall indemnify SMC for any material or repetitional loss caused by the customer or the Workshop Partner.</li>
                <li>All refunds for work completed is to be managed between customer and workshop partner, SMC hold no responsibility for refunds.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import backgroundParallax from "@/components/widgets/background-parallax";

export default {
  name: "quote-requests",
  components: {
    backgroundParallax
  },
  data: () => ({
    notify: true
  })
};
</script>

<style lang="scss" scoped>
.terms-title {
  word-break: break-word;
}

.v-list-item__title {
  overflow: visible;
  white-space: pre-line;
  line-height: 22px;
}

ol {
  counter-reset: list;
}

ol > li {
  list-style: none;
  position: relative
}

ol > li:before {
  content: counter(list, lower-alpha) ") ";
  counter-increment: list;
  position: absolute;
  left: -1.5em;
}

ol li,
ul li {
  margin-bottom: 16px;
}

.smc-bbox {
  @media screen and (max-width: 420px) {
    margin-left: -24px;
    min-width: calc(100vw + 24px);
  }
}
</style>