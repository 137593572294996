<template>
<div class="home container">
  <v-sheet class="smc-sheet-transparent">
    <div class="mt-12 pt-12" style="position: relative"></div>
    <v-sheet
      class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark"
      elevation="6"
    >
      <h1 class="page-header display-1 font-weight-medium white--text">
        About
      </h1>
    </v-sheet>

    <v-card class="box box-rounded smc-card-borderless">
      <v-card-text class="pb-0">
        <div
          v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
          style="height: auto; overflow: scroll;"
        >
          <!-- el1 -->
          <div class="text-justify">
            <!-- el2 -->
            <p><strong>ServiceMyCar.co.za</strong> is an online marketplace for vehicle maintenance and repairs. We provide customers numerous quotations for any vehicle repair or service from various workshops located in the customers vicinity, as well as book the vehicle in on the selected date at the selected workshop. It allows customers to make informed decisions on which workshop they would prefer to have their vehicle serviced at, providing structure to a previously unstructured marketplace. Through many years of motor industry experience we have identified that this is a huge problem for both workshops and customers. Customers face the challenge of finding a trustworthy, transparent and cost-effective workshop whilst workshops (both franchise and non-franchise) face the challenge of increasing volume, securing bookings and having reliable customer support.</p>
            <p>
              <strong>ServiceMyCar.co.za</strong> will allow the customer to conveniently input their vehicle information in a very understandable and time effective manner, completely free of charge. SMC assures this by using a well thought out diagnostic matrix which guides the customer in the right direction to correctly diagnose the issue.
              The accurate diagnosis is then sent to workshops who will compete for the customers business. All quotations are relayed through the SMC platform in a list, with the ability to open and break down each quotation line by line, ensuring transparency (“comparing apples with apples”). The customer can then determine which workshop they would like to book with based on Location, Rating, Price or any other distinguishing factor.
              <strong>SMC audits every workshop on the platform to ensure they live up to the SMC standard</strong>, which ensures the customer can have confidence in the workshop attending to their vehicle. SMC also maintains an accurate service history of every vehicle that the customer can use to ensure the highest possible trade in price for their vehicle in the future.
            </p>
            <p>
              <strong>AMENDMENTS</strong> – The customer is informed through their SMC portal of any additional issues the workshop may find, which he/she must then accept or reject. If it is rejected, that amendment is then re-shopped with other workshops to ensure the best customer experience, ensuring the vehicles issues are attended
            </p>
            <p>Finally, the customer is given an option of an online payment solution or cash on collection.</p>
          </div>
          <!-- dragger will be automatically added here -->
        </div>
      </v-card-text>
      <v-card-title class="headline primary--text font-weight-bold pt-0 pb-2">
        Workshop Locations
      </v-card-title>
      <v-card-text>
        <v-card rounded outlined class="smc-v-card-transparent">
          <v-card-text class="pa-0">
            <v-row>
              <v-col>
                <workshops-map
                  v-bind:placesProp="places"
                ></workshops-map>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <div class="mt-12 pt-12">
      <vehicle-search />
    </div>
  </v-sheet>
</div>
</template>

<script>
import vehicleSearch from "@/components/vehicle-search";
import WorkshopsMap from "@/components/workshops-map.vue";
import { workshopService } from "@/services";

export default {
  name: "about",
  components: {
    vehicleSearch,
    WorkshopsMap
  },
  data: () => ({
    places: []
  }),
  async created() {
    this.places = await workshopService.getWorkshopAddresses();
  }
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";
</style>
